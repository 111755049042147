@import '../reset.scss';
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100&family=Oswald:wght@200;300;306;400&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

// const styles = {
//     container: {
//         height: '40px',
//         border: 'solid, #C4C4C4, 2px',
//         backgroundColor: '#white',
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'space-around',
//         color: '#626161'
//     }
// }

.footer {
    height: 30px;
    width: 100%;
    font-family: 'Oswald', sans-serif;
    display: flex;
    justify-content: center;
    border-top: lightgray solid 5px; 
    position: fixed;
    bottom: 0px;
}

.footer-links {
    height: 30px;
    width: 700px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    text-decoration: none;
}