@mixin flex-positioning-evenly {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
}
@mixin big-header-font {
    font-family: $primary-font;
    font-size: 40px;
    color: black;
    letter-spacing: 2px;
}
$primary-font: 'Oswald', sans serif;
$secondary-font: 'Roboto Condensed', sans serif;

.status {
    width: 180px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: $primary-font;
    letter-spacing: 1px;
    font-weight: normal;
    text-decoration: none;
    a:link, a:visited, a:active {
        text-decoration: none;
        color: black;
    }
    a:hover {
        color: gray;
    }
    
    &--researching{
        @extend .status;
        border: 2px solid rgb(224, 193, 17);
        color: rgb(224, 193, 17);
        text-decoration: none;
        color: black;

    }
    &--networking {
        @extend .status;
        border: 2px solid rgb(151, 118, 9);
        color: rgb(151, 118, 9);
        color: black;

    }
    &--applying {
        @extend .status;
        border: 2px solid rgb(218, 100, 21);
        color: rgb(218, 100, 21);
        color: black;

    }
    &--application-submitted {
        @extend .status;
        border: 2px solid rgb(74, 199, 248);
        color: black;
    }
    &--assessments {
        @extend .status;
        border: 2px solid rgb(2, 135, 139);
        color: rgb(2, 135, 139);
        color: black;

    }
    &--interviewing {
        @extend .status;
        border: 2px solid rgb(35, 66, 167);
        color: rgb(35, 66, 167);
        color: black;

    }
    &--thankyou {
        @extend .status;
        border: 2px solid rgb(81, 38, 121);
        color:  rgb(81, 38, 121);
        color: black;

    }
    &--waiting {
        @extend .status;
        border: 2px solid rgb(95, 94, 95);
        color:  rgb(95, 94, 95);
        color: black;

      
    
    }
    &--offer {
        @extend .status;
        border: 2px solid rgb(63, 175, 49);
        color:  rgb(63, 175, 49);
        color: black;

    }
    &--rejected {
        @extend .status;
        border: 2px solid rgb(194, 54, 19);
        color: rgb(194, 54, 19);
        color: black;

    }
    &--negotiating {
        @extend .status;
        border: 2px solid rgb(148, 50, 123);
        color: rgb(148, 50, 123);
        color: black;

    }
    &--accepted-offer {
        @extend .status;
        border: 2px solid rgb(34, 110, 65);
        color: rgb(34, 110, 65);
        color: black;

    }
    &--rejected-offer {
        @extend .status;
        border: 2px solid rgb(138, 66, 66);
        color:  rgb(138, 66, 66);
        color: black;

    }
}


// @media (max-width: 768px) {
//     .left-header {
//         width: 400px;
//         color: black;
//         font-size: 16px;
//     }    
//     .logo-box {
//         height: 40px;
//         width: 80px;
//         font-size: 22px;
//         letter-spacing: 4px;
//         color: #E0AF66;
//         margin-right: 10px;
//     }
//     .header-left-header {
//         // height: 60px;
//         width: 375px;
//         font-size: 16px;
//     }
//     .right-header {
//         font-size: 12px;
//         letter-spacing: 1px;
//     }
// }

@media (max-width: 768px) {
    .status {
        width: 150px;
        font-size: 12px;
    }
}