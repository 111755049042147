@import '../reset.scss';

@mixin flex-positioning-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  @mixin big-header-font {
    font-family: $primary-font;
    font-size: 40px;
    color: black;
    letter-spacing: 2px;
  }
  
  $primary-font: 'Oswald', sans serif;
  $secondary-font: 'Roboto Condensed', sans serif;

//make globals - and put these mixins and variables and a button style as well. 


.page-job {
    height: 100vh;
    width: 100vw;
    @include flex-positioning-evenly();    
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    margin-top: 20px;
}

.job-container-jobjs {
    // margin-top: 20px;
    margin-left: 30px;
    width: 600px;
    height: 750px;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}

//same as dashboard.scss title-bar (do a variable or mixin)
.title-bar-jobview {
    height: 60px;
    width: 600px;
    display: flex;
    // border: solid 1px black;
    @include flex-positioning-evenly;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    @include big-header-font;
    margin-bottom: 40px;
}

.title-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.title-edit {
    height: 40px;
    width: 370px;
    border: none;
    border-bottom: 1px solid black;
    font-family: $primary-font;
    font-size: 40px;
    letter-spacing: 2px;
}

.edit-delete-box {
    @include flex-positioning-evenly();
    align-items: flex-end;
    flex-direction: row;
}
 
.details-container-jobview {
    height: 700px;
    width: 600px;    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // overflow: auto;
    // resize: none;
    // padding-top: 20px;
}

.detail-item-jobview {
    height: 18px;
    width: 600px;
    display: flex;
    margin-bottom: 25px;
    // flex-direction: row;
}

.label {
    font-family: $primary-font;
    font-size: 16px;
    width: 140px;
    color: black;
    display: flex;
}

.value {
    width: 500px;
    border: none;
    border-bottom: 1px solid black;
    font-family: $secondary-font;
    letter-spacing: 1px;
    font-size: 15px;
    padding-left: 5px;
}

.textarea-line-jobview {
    height: 100px;
    width: 600px;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.textarea-value {
    display: flex;
    width: 500px;
    height: 100px;
    overflow: auto;
    resize: none;
    font-family: $secondary-font;
    letter-spacing: 1px;
    font-size: 15px;
    padding: 5px;
    border-bottom: 1px solid black;
}

.textarea-value-edit {
    border: 1px solid black;
}

.dropdown-container-jobview {
    height: 20px;
    width: 600px;
    font-family: $secondary-font;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.dropdown {
    display: flex;
    overflow: auto;
    height: 20px;
    width: 500px;
    font-family: $secondary-font;
    font-size: 15px;
}

.options {
    height: 28px;
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.detail-jobview-textarea {
    height: 100px;
    width: 600px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.status-container {
    width: 300px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}    

.job-view-status-title {
    height: 60px;
    @include big-header-font;
    display: flex;
    align-items: flex-end;
    font-size: 35px;
    margin-bottom: 20px;
}

.status-header {
    display: flex;
    align-items: flex-end;
}

.list-w-bar {
    margin-top: 20px;
    height: 450px;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.status-line {
    display: flex;
    height: 450px;
    width: 3px;
    background-color: lightgray;
    margin-left: 50px;

}

.status-item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    .item {
        margin-left: 10px;
        display: flex;
        align-items: center;
        width: 250px;
        height: 30px;
        padding-left: 5px;
        border: none;
        color: black;
    }
}
//Right side of the page.
.status-dash {
    display: flex;
    height: 3px;
    width: 35px;
    background-color: lightgray;
    margin-right: 5px;
}

.status-list {
    font-family: $primary-font;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 2px;
    height: 450px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    // margin-left: 20px;
    // padding-right: 50px;
}

.btn-jobview {
    background-color: white;
    color: black;
    border: solid 1px black;
    font-family: $primary-font;
    font-size: 14px;
    margin-left: 20px;
    letter-spacing: 1px;
    display: flex;
    align-items: flex-end;
}

.normal-text {
    font-family: $secondary-font;
    font-size: 14px;
    letter-spacing: 0px;
    color: black;

}

.researching {
    padding: 5px;
    font-size: 16px;
    border: 3px solid  rgb(224, 193, 17);
    color:  rgb(224, 193, 17);
    color: black;
    
}
.networking {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(151, 118, 9);
    color:  rgb(151, 118, 9);
    color: black;
}
.applying {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(218, 100, 21);
    color: rgb(218, 100, 21);
    color: black;
}
.application-submitted {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(74, 199, 248);
    color: black;
}
.assessments {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(2, 135, 139);
    color: rgb(2, 135, 139);
    color: black;
}
.interviewing {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(35, 66, 167);
    color: rgb(35, 66, 167);
    color: black;
}
.thankyou {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(81, 38, 121);
    color:  rgb(81, 38, 121);
    color: black;
}
.waiting {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(95, 94, 95);
    color:  rgb(95, 94, 95);
    color: black;
}
.offer {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(63, 175, 49);
    color:  rgb(63, 175, 49);
    color: black;
}
.rejected {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(194, 54, 19);
    color: rgb(194, 54, 19);
    color: black;
}
.negotiating {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(148, 50, 123);
    color: rgb(148, 50, 123);
    color: black;
}
.accepted-offer {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(34, 110, 65);
    color: rgb(34, 110, 65);
    color: black;
}
.rejected-offer {
    padding: 5px;
    font-size: 16px;
    border: 3px solid rgb(138, 66, 66);
    color:  rgb(138, 66, 66);
    color: black;
}

@media (max-width: 768px) {
    .job-container-jobjs {
        width: 400px;
    }
    .details-container-jobview {
        width: 400px;
    }
    .detail-item-jobview {
        width: 400px;
    }

    .title-bar-jobview {
        width: 400px;
    }
    .label {
        font-size: 13px;
        width: 100px;
    }
    .value {
        width: 300px;
    }
    .textarea-line-jobview {
        width: 300px;
    }
    .status-line {
        margin-left: 5px;
    }
    .status-dash {
        width: 20px;
    }
    .status-container {
        width: 200px;
    }
    .status-list {
        width: 200px;
    }
    .list-w-bar {
        width: 200px;
    }
    .normal-text {
        font-size: 12px;
    }
}