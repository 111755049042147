@mixin flex-positioning-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  @mixin big-header-font {
    font-family: $primary-font;
    font-size: 40px;
    color: black;
    letter-spacing: 2px;
  }
  
  $primary-font: 'Oswald', sans serif;
  $secondary-font: 'Roboto Condensed', sans serif;

//make globals - and put these mixins and variables and a button style as well. 


.page-add {
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;
}

.job-container-add {
    // margin-top: 20px;
    margin-left: 30px;
    width: 650px;
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    // border: solid 1px black;
}
.title-bar-add-job {
    height: 80px;
    width: 650px;
    display: flex;
    // border: solid 1px black;
    @include flex-positioning-evenly;
    flex-direction: row;
    justify-content: space-between;
    @include big-header-font;
    
}
   

.btn {
    background-color: white;
    color: black;
    border: solid 1px black;
    font-family: $primary-font;
    font-size: 14px;
    margin-left: 20px;
    letter-spacing: 1px;
}

.details-container {
    height: 700px;
    width: 650px;    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // overflow: auto;
    // resize: none;
    padding-top: 20px;
}

.detail-item {
    height: 18px;
    width: 650px;
    display: flex;
    margin-bottom: 25px;
    // flex-direction: row;
}

.item {
    font-family: $primary-font;
    font-size: 16px;
    width: 150px;
    color: black;
    display: flex;
}

.value {
    width: 500px;
    border: none;
    border-bottom: 1px solid black;
    font-family: $secondary-font;
    font-size: 15px;
    padding-left: 5px;
}

//Why is this not doing anything?
.detail-item-textarea {
    height: 100px;
    display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.addjob-textarea {
// .description .notes {
    display: flex;
    height: 100px;
    width: 500px;
    overflow: auto;
    resize: none;
    font-size: 14px;
    font-family: $secondary-font;
    letter-spacing: 1px;
    border: 1px solid black;
    // margin-bottom: 25px;
    resize: none;
}


.status-dropdown-container-line {
    height: 20px;
    width: 650px;
    font-family: $secondary-font;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;

}

.dropdown {
    display: flex;
    overflow: auto;
    height: 20px;
    width: 500px;
    font-family: $secondary-font;
    font-size: 15px;

}

.options {
    height: 28px;
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}