@mixin flex-positioning-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  @mixin big-header-font {
    font-family: $primary-font;
    font-size: 40px;
    color: black;
    letter-spacing: 2px;
  }
  
  $primary-font: 'Oswald', sans serif;
  $secondary-font: 'Roboto Condensed', sans serif;

//make globals - and put these mixins and variables and a button style as well. 


.page {
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: space-around;

}

.job-container {
    margin-top: 40px;
    width: 600px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

//same as dashboard.scss title-bar (do a variable or mixin)
.title-bar {
    height: 80px;
    width: 600px;
    display: flex;
    // border: solid 1px black;
    @include flex-positioning-evenly;
    flex-direction: row;
    justify-content: space-between;
    @include big-header-font;
    
}

.about-text {
    height: 600px;
    width: 600px;
    font-family: $secondary-font;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    margin-top: 30px;
    // margin-left: 30px;
}

.about {
    margin-bottom: 10px;
}

.gray-line {
    width: 600px;
    height: 4px;
    background-color: lightgray;
    margin-bottom: 40px;
}
@media (max-width: 768px) {
    .job-container {
        width: 500px;
        height: 500px;
    }
    .title-bar {
        width: 500px;
        font-size: 30px;
    }
    .about-text {
        width: 500px;
        font-size: 20px;
    }
    .gray-line {
        width: 500px;
    }
}