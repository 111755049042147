// @import '../reset.scss';

@mixin flex-positioning-evenly {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
}
@mixin big-header-font {
    font-family: $primary-font;
    font-size: 40px;
    color: black;
    letter-spacing: 2px;
}
$primary-font: 'Oswald', sans serif;
$secondary-font: 'Roboto Condensed', sans serif;

.dash-page {
    height: calc(100% - 105px);
    width: 100vw;
    background-color: white;
    @include flex-positioning-evenly;
    align-items: flex-start;
 
}

.big-list-container {
    // margin-top: 10px;
    width: 700px;
    height: 600px;
}

.title-bar-jobs {
    height: 80px;
    width: 700px;
    @include flex-positioning-evenly;
    @include big-header-font();
    justify-content: space-between;
}

.dashboard-job-title {
    @include flex-positioning-evenly;
    justify-content: space-between;
    width: 300px;
    font-size: 40px;
}

.status-title{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 200px;
    font-size: 35px;
    // font-family: $secondary-font;
    letter-spacing: 2px;
    // margin-left: 70px;
}

.select-line-box {
    height: 80px;
    width: 450px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.line-box {
    height: 60px;
    width: 20px;
    border: 1px black solid;
    border-left: none;
    display: flex;
    // margin-top: 5px;
    align-items: center;
}

.line-container {
    height: 80px;
    width: 330px;
    // @include flex-positioning-evenly();
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-end;
}

.box-line-up {
    display: flex;
    height: 40px;
    width: 330px;
    border-bottom: 1px black solid;
}

.box-line-down {
    display: flex;
    height: 40px;
    width: 330px;
}

.btn-container {
    display: flex;
    justify-content: flex-end;
}

.btn {
    background-color: white;
    color: black;
    border: solid 1px black;
    font-family: $primary-font;
    font-size: 14px;
    margin-left: 20px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
// .add-btn {
//     display: flex;
//     justify-content: flex-end;
// }

.map-list-container {
    height: 500px;
    width: 730px;
    overflow: auto;
    // border: solid 1px black;
}

.job-map-box {
    width: 700px;
    height: 50px;
    border-bottom: black solid 1px;
    display: flex;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    a:link, a:visited, a:active {
        text-decoration: none;
        color: black;
    }
    a:hover {
        text-decoration: none;
        color: gray;
    }
    .job-map-box:link, .job-map-box:visited, .job-map-box:active {
        text-decoration: none;
        color: black;
    }
    .job-map-box:hover {
        text-decoration: none;
        color: gray;
    }
    // padding: 2px;
}

.job-company-box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-decoration: none;
    margin-right: 10px;
    width: 510px;
    // padding: 5px;
}


.company-name {
    // width: 510px;
    font-family: $secondary-font;
    font-size: 14px;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
}

.display-none {
    display: hidden;
}


.job-title {
    // width: 510px;
    font-family: $primary-font;
    font-size: 20px;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: solid 1px lightgray;
    text-decoration: none;
    text-decoration-line: none;
    a:link, a:visited, a:active {
        text-decoration: none;
        color: black;
    }
    a:hover {
        text-decoration: none;
        color: gray;
    }
}

@media (max-width: 768px) {
    .big-list-container {
        // margin-top: 10px;
        width: 500px;
        height: 500px;
    }
    .title-bar-jobs {
        height: 70px;
        width: 500px;
    }
    .dashboard-job-title {
        font-size: 30px;
        letter-spacing: 1px;
    }
    .status-title {
        font-size: 28px;
        letter-spacing: 1px;
    }
    .select-line-box {
        width: 250px;
    }
    .line-container {
        width: 230px;
    }
    .box-line-down  {
        width: 230px;
    }


    .box-line-up {
        width: 230px;
    }

    .map-list-container {
        width: 500px;
    }
    .job-map-box {
        width: 500px;
    }

}