// @import '../reset.scss';
// $breakpoint-tablet: 768px;

@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100&family=Oswald:wght@200;300;306;400&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.header-container {
    height: 75px;
    width: 100%;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Oswald', sans-serif; 
    font-size: 16px;
    letter-spacing: 2px;
    border-bottom: lightgray solid 5px;   
}

.header-left-header {
    height: 60px;
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
}

.logo-box {
    height: 50px;
    width: 110px;
    border: solid black 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    color: #E0AF66;
    letter-spacing: 5px;
    font-size: 35px;
    

}

.right-header {
    height: 60px;
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;
    text-decoration: none;
    a:link, a:visited, a:active {
        text-decoration: none;
        color: black;
    }
    a:hover {
        color: gray;
    }
}


@media (max-width: 768px) {
    .left-header {
        width: 400px;
        color: black;
        font-size: 16px;
    }    
    .logo-box {
        height: 40px;
        width: 80px;
        font-size: 22px;
        letter-spacing: 4px;
        color: #E0AF66;
        margin-right: 10px;
    }
    .header-left-header {
        // height: 60px;
        width: 375px;
        font-size: 16px;
    }
    .right-header {
        font-size: 12px;
        letter-spacing: 1px;
    }
}